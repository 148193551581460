import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Banner } from '../components/banner';
import { TiggbeeContactUs } from '../components/tiggbee-contact-us';
import { SchoolpageBannerContent } from '../components/schoolpage-banner-content';
import { SchoolpageContent } from '../components/schoolpage-content';
// import school_page_banner from '../images/school-page-banner.jpg';

const BusinessPage = () => (
    <Layout>
        <SEO title="School Page" />
        <Banner
            imgURL='school-page-banner.jpg'
            imgHeight={536}
            content={<SchoolpageBannerContent />}
        />
        <SchoolpageContent />
        <TiggbeeContactUs
          titleElement={<>Our Mission is to empower our students regardless of economic or geographic barriers with the hope and knowledge of the opportunities to be happy and successful individuals.</>}
        />
    </Layout>
)

export default BusinessPage
