import React from "react"
import { Link } from "gatsby"
import "./content.css"
import "./emailcontactus-content.css"
import { BaseService } from "../services/base-service"
export class SchoolpageContent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      madeForSchoolsList: [
        {
          icon: "made-for-school-icon1.png",
          titleElement: (
            <>
              WE BRING
              <br />
              INDUSTRY TO YOU
            </>
          ),
        },
        {
          icon: "made-for-school-icon2.png",
          titleElement: (
            <>
              DELIVERS KNOWLEDGE,
              <br />
              HOPE & OPPORTUNITIES
            </>
          ),
        },
        {
          icon: "made-for-school-icon3.png",
          titleElement: (
            <>
              INTERACTIVE
              <br />
              EVENTS
            </>
          ),
        },
        { icon: "made-for-school-icon4.png", titleElement: <>IT'S FREE</> },
        {
          icon: "made-for-school-icon5.png",
          titleElement: (
            <>
              BREAKS
              <br />
              ECONOMIC
              <br />
              BARRIERS
            </>
          ),
        },
        {
          icon: "made-for-school-icon6.png",
          titleElement: (
            <>
              BREAKS
              <br />
              GEOGRAPHIC
              <br />
              BARRIERS
            </>
          ),
        },
        {
          icon: "made-for-school-icon7.png",
          titleElement: (
            <>
              CORRELATES
              <br />
              STUDIES TO
              <br />
              OPPORTUNITIES
            </>
          ),
        },
        { icon: "made-for-school-icon8.png", titleElement: <>IT'S SAFE</> },
      ],
      isOnVideoPlayMode: false,
    }
  }

  handleOnClickPlayVideo = () => {
    const WatchTiggbeeInAction = document.getElementById("WatchTiggbeeInAction")
    if (WatchTiggbeeInAction) {
      this.setState(
        (state) => {
          const newState = {
            isOnVideoPlayMode: true,
          }
          return newState
        },
        () => {
          if (WatchTiggbeeInAction.play) WatchTiggbeeInAction.play()
        }
      )
    }
  }

  renderVideoElement = () => {
    const element = (
      <>
        <div className="schoolpage-content-video-player-wrapper-div">
          <div className="video-player-container-div">
            <video controls id="WatchTiggbeeInAction" preload="metadata">
              <source
                src={BaseService.getVideoURL(
                  "/public/public-files/Tiggbee+In+Action.mp4"
                )}
                type="video/mp4"
              />
            </video>
          </div>
          {!this.state.isOnVideoPlayMode ? (
            <>
              <div
                onClick={this.handleOnClickPlayVideo}
                className="video-player-poster-div"
                style={{
                  backgroundImage:
                    "URL(/email-contact-us-video-background.png)",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              ></div>
              <img
                onClick={this.handleOnClickPlayVideo}
                className="video-player-play-img"
                src="/email-contact-us-video-play.svg"
                alt=""
              />
            </>
          ) : (
            <></>
          )}
        </div>
      </>
    )
    return element
  }

  setCareerPart = () => {
    const content = (
      <>
        <div className="homepage-content-technology-container schoolpage-content-leader-container">
          <div className="homepage-content-technology-title">
            Work-Based Learning
          </div>
          <div className="homepage-content-technology-description schoolpage-content-technology-description">
            Tiggbee engages students with role models, successful individuals
            and their businesses which illuminates them on how their studies can
            lead to future careers.
          </div>
          <div className="homepage-content-technology-paragraph-container schoolpage-content-technology-paragraph-container">
            <div>
              <div className="homepage-content-technology-paragraph-title schoolpage-content-technology-paragraph-title">
                Tiggbee Inspiration
                <img
                  alt=""
                  src={"/business-page-leader-paragraph-options-icon.png"}
                />
              </div>
              <div className="homepage-content-technology-paragraph-texts schoolpage-content-technology-paragraph-texts">
                <div>
                  Tiggbee is committed to creating a meaningful connection
                  between students' academic studies and future career
                  opportunities, starting from the 3rd grade all the way through
                  graduation.
                </div>
                <div>
                  Our platform provides students with invaluable first-hand
                  experiences of what it takes to succeed in their desired
                  careers and helps them explore and plan the educational path
                  necessary to achieve their goals.
                </div>
                <div>
                  Unlike other social platforms, Tiggbee gives teachers control
                  over the content their students engage with, ensuring a safe
                  and productive learning environment. Our focus is solely on
                  empowering students to explore and achieve their career
                  aspirations with the guidance of their educators.
                </div>
                <div>
                  By empowering students to explore their passions and make
                  meaningful connections between their studies and future
                  careers, Tiggbee is paving the way for a more informed and
                  successful future workforce.
                </div>
              </div>
              <div className="schoolpage-content-technology-paragraph-button">
                {/* <a
                  href="https://event.tiggbee.com/authentication/createaccount"
                  className="homepage-content-technology-paragraph-button"
                >
                  Create account
                </a> */}
                <Link
                  className="homepage-content-technology-paragraph-button"
                  to="/contact-us-page"
                >
                  Contact Us
                </Link>
                <div className="schoolpage-content-technology-paragraph-button-description">
                  (It's Free!)
                </div>
              </div>
            </div>
            <div className="schoolpage-content-technology-paragraph-color-background">
              <div>Watch Tiggbee In Action</div>
              {/* <img alt="" src={"/school-page-paragraph-background.png"} /> */}
              {this.renderVideoElement()}
            </div>
          </div>
        </div>
      </>
    )
    return content
  }

  setIndustriesList = () => {
    const content = (
      <>
        <div className="studentpage-quote-container">
          <div className="schoolpage-content-industries-container">
            <div className="business-logo business-logo1">
              <div></div>
              <div></div>
            </div>
            <div className="business-logo business-logo2">
              <div></div>
              <div></div>
            </div>
            <div className="schoolpage-content-industrie-item schoolpage-content-industrie-item3">
              Business, Finance and Professional Services
            </div>
            <div className="schoolpage-content-industrie-item schoolpage-content-industrie-item2">
              Education, Child Development and Family Services
            </div>
            <div className="schoolpage-content-industrie-item schoolpage-content-industrie-item1">
              Energy, Environment and Utilities
            </div>
            <div className="schoolpage-content-industrie-item schoolpage-content-industrie-item2">
              Engineering and Architecture
            </div>
            <div className="schoolpage-content-industrie-item schoolpage-content-industrie-item3">
              Fashion and Interior Design
            </div>
          </div>
        </div>
      </>
    )
    return content
  }

  setSharingKnowledgePart = () => {
    const content = (
      <>
        <div className="homepage-content-technology-container businesspage-content-technology-container schoolpage-content-sharing-knowledge-container">
          <div className="businesspage-content-technology-impact schoolpage-content-sharing-knowledge-title">
            Sharing Knowledge Leads to Inspiration
          </div>
          <div
            className="homepage-content-technology-paragraph-container businesspage-content-impact-paragraph-container schoolpage-content-sharing-knowledge-paragraph-container"
            style={{
              backgroundImage: `url(/schoolpage-content-sharing-knowledge-background.png)`,
            }}
          >
            <div className="homepage-content-technology-paragraph-texts businesspage-content-technology-paragraph-texts schoolpage-content-sharing-knowledge-paragraph-texts">
              <div>
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
                euismod bibendum laoreet. Proin gravida dolor sit amet lacus
                accumsan et viverra justo commodo."
                <div>- by Great Teacher</div>
              </div>
              <div>
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
                euismod bibendum laoreet. Proin gravida dolor sit amet lacus
                accumsan et viverra justo commodo."
                <div>- by Great Teacher</div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
    return content
  }

  setMadeForSchoolItemContent = (icon, titleElement) => {
    const content = (
      <>
        <div className="schoolpage-content-made-for-schools-item">
          <img alt="" src={`/${icon}`} />
          <div>{titleElement}</div>
        </div>
      </>
    )
    return content
  }

  setMadeForSchoolsPart = () => {
    const content = (
      <>
        <div className="schoolpage-content-made-for-schools-container">
          <div className="schoolpage-content-made-for-schools-title">
            Made for Schools
          </div>
          <div className="row">
            {this.state.madeForSchoolsList.map((item, index) => (
              <div className="col-6 col-md-3" key={index}>
                {this.setMadeForSchoolItemContent(item.icon, item.titleElement)}
              </div>
            ))}
          </div>
        </div>
      </>
    )
    return content
  }

  renderCustomerTestimoniesElement = () => {
    const element = (
      <>
        <div className="schoolpage-content-made-for-schools-container schoolpage-content-customer-testimonies-container">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="schoolpage-content-customer-testimonies-content-div">
                “My students love to see if their question makes it into the
                response video. Before I play the video, I go over the
                information on the person and the salaries under the other tabs.
                I also find local jobs in our area so we can compare the job
                descriptions and the salaries. Thanks for providing us with a
                virtual opportunity to explore jobs.”
                <div>- Alexandria City Public Schools, VA</div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="schoolpage-content-customer-testimonies-content-div">
                “It’s simple and impactful. Tiggbee improves upon any career
                pathway with minimal effort. Our students are introduced to
                different career opportunities within different industries every
                week. It is not just what the presenters are saying - it’s how
                they are saying it.
                <br />
                <br />
                Our students feel engaged when the presenters respond to the
                student’s questions via video. The passion shared by these
                presenters is the catalyst to give me the opportunity to have
                in-depth conversations with my students regarding their
                education and their future careers.”
                <div>- San Bernardino City Unified, CA</div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
    return element
  }

  render() {
    const html = (
      <>
        {this.setCareerPart()}
        {/* { this.setIndustriesList() } */}
        {this.setMadeForSchoolsPart()}
        {this.renderCustomerTestimoniesElement()}
      </>
    )
    return html
  }
}
