import React from "react"
import "./banner.css"
export class SchoolpageBannerContent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const html = (
      <>
        <div className="banner-content">
          <div
            className="banner-content-background"
            style={{
              backgroundImage: `url(/banner-gradient-background-1.png)`,
            }}
          ></div>
          <div className="banner-title homepage-banner-title business-banner-title school-banner-title">
            Exploration + Inspiration + Learning
            <br />
            Introducing Your Students to Industry
          </div>
        </div>
      </>
    )
    return html
  }
}
